/********************************* 
Qrata Theme scss 
Author: INSONIX    
Year :2020
**********************************/

body {
    font-family: 'Roboto'!important;
    background: #f1f1f1!important;
}

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

/* Your customizations here */

$theme-colors: 
( 
primary:#00b59d, 
info:#3c4043,
secondary:#018e7e,
dark:#12493d, 
light:#d1d1d1,
warning:#f8c347,
success:#28a745,
white:#fff, 
danger: #b13221);

$colors:(
    primary:#00b59d, 
    info:#3c4043,
    secondary:#018e7e,
    dark:#12493d, 
    light:#d1d1d1,
    warning:#f8c347,
    success:#28a745,
    white:#fff, 
    danger: #b13221);

$defaultOverride : #674aee;

@import "bootstrap";
a.nav-link {
    text-decoration: none;
    color: #00796B;
    transition: color 0.2s ease-out;
}
a.nav-link:hover {
    background: #f1f1f1;
}
.border-5 {
    border-width: 5px;
}
/*********** dashboard App icon css********/
.app-icon {
    max-width: 100%;
 
}
.count {
    font-size: 5.7rem;
    font-weight: normal;
    font-family: 'Montagu Slab', serif;
    color:#000;
}
.default {
    color:$defaultOverride;
}
// .active {
//     color: #004D40!important;
//     background: #E0F2F1;
//     .text-light {
//         color: #223444!important;
//     }
// }

.sub-menu {
    .nav-link {
        padding-left: 58px !important;
        line-height: 25px!important;
    }
}

.page-item.active .page-link{
        background: $defaultOverride;
}

.nav-icon {
    z-index: 1;
}

.sidebar {
    min-width: 17%;
    max-width: 17%;
    background: #fff;
    color: #adb5bd;
    margin-left: -17%;
    transition: all 0.5s;
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index:999;
    .nav-link {
        display:block;
        padding: .5rem 1rem;
        font-family: 'Roboto';
        font-weight: normal;
        line-height: 38px;
        align-items: center;
        position: relative;
    }
    .sidebar-icon
    {
        right:15px;
        top:18px;
    }
}

.sidebar.is-open {
    margin-left: 0;
    transition: .8s;
}

.sidebar-header>button {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.nav-icons {
    min-width: 32px;
    display: inline-block;
    margin-right: 11px;
    font-size: 18px;
}

.dropdown-menu-right {
    border: 0px;
    box-shadow: 0 .125rem 1.25rem rgba(0, 0, 0, .075)!important;
}

.cursor-pointer {
    cursor: pointer;
}

/******** nav-item dropdown active bg******************/
.dropdown-item.active, .dropdown-item:active {
     background-color: #674aee;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */

.content {
    padding: 20px;
    margin-left: 0;
    transition: all 0.8s;
}

.content.is-open {
    margin-left: 17%;
    width: 83%;
}

.info-box-icon {
    font-size: 50px;
    color: #ffffff30;
}

.btn:focus {
    outline: none !important;
    box-shadow: none;
}

@media only screen and (max-width: 575.98px) {
    .content.is-open {
        /*margin-left: 100%;*/
        display: none;
        transition: all 0.8s;
    }
    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.8s, height 0s;
        height: 100vh !important;
    }
    .sidebar.is-open>.sidebar-header button {
        display: block;
    }
    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}
/**************** Advertisement banner css ******************/
.qrataads-728-90 {
    width: 728px;
    height: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.qrataads-320-50 {
    width: 320px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.qrataads-300-250 {
    width: 300px;
    height: 250px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.qrataads-200-200 {
    width: 200px;
    height: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.price-tag {
    font-size: 55px;
}
/************** tooltip size css ****************/
.tooltip-inner {
    max-width: 400px!important;
   }

/************** search page tool-bar***************/
.score-box {
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
}
.s-number {
    transform: rotate(-45deg);
}
.border-left-tool {
    height: calc(100vh - 160px);
    border-left: 2px solid #018e7e;
    left: 34px;
    top: 7px;
}
/************** supporthelp***************/
.date-time {
    right: 15px;
    top: 6px;
}
/* ---------------------------------------------------
      Scroll Bar  STYLE
  ----------------------------------------------------- */
  
/* width */

 ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
}


/* Track */

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-right: 1px solid #7e929f;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #7e929f;
    border-radius: 10px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #7e929f;
    ;
}


/* Handle on hover */

 ::-moz-scrollbar-thumb:hover {
    background: rgb(167, 159, 159);
}


/* width */

 ::-moz-scrollbar {
    width: 8px;
}


/* Track */

 ::-moz-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-right: 2px solid #E93329;
}


/* Handle */

 ::-moz-scrollbar-thumb {
    background: #E93329;
    border-radius: 10px;
}
ṭ
.resizer {
    display: inline-block;
    width: 2px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
    &.isResizing {
        background: grey;
    }
}

.user {
    background-color: red;
    font-size: large;
    text-align: right;
}

/**************  Qrata-step-form  bottom progress bar css *****************/
#qrata-step-form {
    padding: 0;
    list-style-type: none;
     font-size: 12px;
    clear: both;
    line-height: 1em;
    margin: 0 -1px;
    text-align: center;
 li {
    float: left;
    padding: 3px 30px 3px 40px;
    background: #d1d1d1;
    color: #fff;
    position: relative;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    width: 100%;
    margin: 0 1px;
    margin-top: 15px;
}
li:before {
    content: '';
    border-left: 6px solid #fff;
    border-top: 3px solid transparent;
    border-bottom: 4px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}
li:after {
    content: '';
    border-left: 6px solid #d1d1d1;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    position: absolute;
    top: -1px;
    left: 100%;
    z-index: 20;
}
 li.active {
    background: #00b59d;
}
 li.active:after {
    border-left-color: #00b59d;
}
}
.d-content {
    display:contents;
}
/********************** loader css **************/
.page-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 9999;
}
.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,.5);
}
/* Bio page Css */
.user-img {
   
    display: block;
    position: relative;
    width:120px;
    height:120px;

    img {
width:120px;
height:120px;
    }
  
}
.upload-btn {
    position: absolute;
    bottom: 0px;
    right: 5px;
}
// BIO DISPLAY NAME
.display-name-input {
    height: 35px;
    border: 0;
    background: none;
    color: white;
    border-bottom: 2px solid white;
    outline: 0;

    &::placeholder {
        color:white;
    }
}
// BIO TEXT SAVE BUTTON
.save-bio-btn {
    font-size: 14px;
    width: 70px;
    background: #00b59d;
    border-radius: 5px;
    color: white;
    padding: 2px 3px;
}

//BIO TOGGLE BUTTON 
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;

  
 input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #018e7e;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #018e7e;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

}

/*** datepicker css *********/

.react-datepicker-popper
{
    z-index: 999!important;
}

.ql-container.ql-snow {
    border: 0px !important;
}

.w-80px{
    width: 80px !important;
}

.outline-focus-0:focus {
    outline: 0;
}

.success-icon
{
    font-size:92px;
}

.oneline-text 
{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    border: none !important;
    border-top: 1px solid lightgray !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}

/*************  Table Header - Footer Sticky css *************/

     .table-scroll{
       max-height:75vh;  
        overflow:auto;
         thead tr:nth-child(1) th{
            background: white;
            position: sticky;
            top: 0;
            z-index: 10;
            border:0px;
          }
          thead tr:nth-child(2) th{
            background: white;
            position: sticky;
            top: 32px;
            z-index: 10;
            border:0px;
          
          }
     
          tfoot tr:nth-child(1) td{
              background: white;
              position: sticky;
              bottom: 0;
              z-index: 10;
            }
      }
      .inner-table
      {
        thead tr:nth-child(1) th{
            background: white;
            position: sticky;
            top: 0;
            z-index: 1;
          } 
      }



      .table th, .table td {
        padding: 4px 8px!important;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
    }
      
// Table sorting list
.sorting-up
{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 3px solid #f2de43 !important;
}

.sorting-down
{
    width:100%;
    position:absolute;
    bottom:0;
    left: 0;
    border-bottom: 3px solid #f2de43 !important;
}



/*************** global search css ****************/

.global-search-box
{
    top: 12px;
    right: 17%;
}
.login-captcha{
    div {
        display:flex;
        flex-wrap: initial;
        input {
            height: calc(1.8em + 0.75rem + 2px);
            width: 154px;
            border: 1px solid #ced4da;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            outline: 0;
            background-clip: padding-box;
            border-radius: 4px;
        }
    }
}
.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
}

.collapse-icon {
    position: absolute;
    right: 15px;
}

@media (max-width:768px)
{
    .a-search
    {
        display:none;
    }
}
td.oneline-text-p p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
}

.float-btn{
    width: 50px;
    height: 50px;
    background: #674aee;
    box-shadow: 1px 1px 10px #918b8b;
    border-radius: 50%;
    position: fixed;
    right: 3px;
    bottom: 10px;
    z-index: 999;
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s;

    &:hover , &:active , &:focus , &:focus-visible{
      box-shadow: 2px 2px 15px #918b8b;
    
    }
}

.align-btn {
    margin-top: 33px;
}

.accordion>.card {
    overflow: unset !important;
}
/*************** comments attachment upload css  start***********/
.attachment-icon 
{
    position: absolute;
    right: 30px;
    top: 6px;
    border: 0px;
    color: #9a9595;

    .form-group {
        background: #fff;
        margin-bottom: 0px;
        height: 30px;
        position: absolute;
        min-width: 20px;
    }
    .custom-file-label {
        position: absolute;
        top: -29px;
        right: 68px;
        left: -100px;
        z-index: 1;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 0px solid #ced4da;
        border-radius: 0.25rem;
        width: 100px;
        height: 34px;
    }
    .custom-file-input:focus ~ .custom-file-label {
        border-color: transparent;
        box-shadow: 0 0 0 0.2rem transparent;
    }
    .custom-file-label[data-browse]::after {
        content: attr(data-browse);
        display: none;
    }
}
/*************** comments attachment upload css  end***********/
.cards-c-title
{
    font-family: 'Montagu Slab', serif;
    font-size: 2.4rem;
    color: #000;
    // width: min-content;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    // line-height: 57px;
    // word-spacing: 30px!important;
    text-transform: capitalize;
}
//  .count-sec
//  {
//      position: absolute; 
//     width:100%;
//      left:0; 
//     right:0;    
//     bottom:30px;    
//  }
 .card-d-title
 {
     height:150px;
 }
.dash-cards
{
    .top-body {
        min-height: 270px;
    }
}
.table-bordered th, .table-bordered td {
    font-family: 'Roboto';
    font-weight: normal;
}
.dropdown-menu {

    font-family: 'Roboto';
    font-weight: normal;
    line-height: 38px;
}
a.nav-link.active {
    background: #f1f1f1;
}
.form-label {
    font-size: 12px;
   margin-bottom:0px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #cbd2d2!important;
  }
  ::-webkit-select-placeholder { /* Chrome/Opera/Safari */
    color: #cbd2d2!important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #cbd2d2!important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #cbd2d2!important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #cbd2d2!important;
  }
  .css-1wa3eu0-placeholder {
    color: #cbd2d2!important;
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
}

.bg-highlight {
    background-color: #674aee!important;
 
}
.display{
    font-size: 18px;
}
/*********** editor toolbar css ******************** */
.ql-toolbar {
    border-right: 0px!important;
   border-left:0px!important;
}

/***************** feedback tooltip issue  ***************/ 
.feedback-icon
{
    right: 20px;
    bottom: 20px;
}








.scale-bar-wrapper
{
    width:100px;
    height:100%;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.fact-scale
{
    position:absolute;
    transition: transform .5s; 
    height:5%;
    width:100%;
    bottom:0;
    left:0;
    right:0;
    background:#00af50;
    display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
text-transform: uppercase;
}
.truth-scale
{
    position:absolute;
    transition: transform .5s;
    height:45%;
    width:100%;
    bottom:5%;
    left:0;
    right:0;
    background:#ffc000;
    display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
text-transform: uppercase;
}

.belief-scale
{
    position:absolute;
    transition: transform .5s;
    height:40%;
    width:100%;
    bottom:50%;
    left:0;
    right:0;
    background:#b3c6e7;
    display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
text-transform: uppercase;
}
.non-fact-lie-scale
{
    position:absolute;
    transition: transform .5s; 
    height:10%;
    width:100%;
    bottom:90%;
    left:0;
    right:0;
    background:#fe0000;
    display: flex;
align-items: center;
justify-content: center;
font-weight: bold;

text-transform: uppercase;
padding: 10px;
}
.scaler-outer-div
{
    height:500px;
    width: 100px;
margin: 0 auto;
cursor: pointer;

}
.fact-value {
color: #000;
position: absolute;
left: -72px;
bottom: 3%;
font-size: 20px;
}
.fact-value {
color: #000;
position: absolute;
left: -70px;
bottom: 2%;
font-size: 12px;
}

.truth-value {
color: #000;
position: absolute;
left: -70px;
bottom: 25%;
font-size: 12px;
}
.belief-value {
color: #000;
position: absolute;
left: -70px;
bottom: 65%;
font-size: 12px;
}
.non-fact-lie-value {
color: #000;
position: absolute;
left: -70px;
bottom: 95%;
font-size: 12px;
}
.non-fact-lie-scale:hover {
color:#fff;
    transform: scale(1.1);
    z-index: 999;
}
.truth-scale:hover
{
    color:#fff;
    transform: scale(1.1);
    z-index: 999;
}
.belief-scale:hover
{
    color:#fff;
    transform: scale(1.1);
    z-index: 999;
}
.fact-scale:hover
{
    color:#fff;
    transform: scale(1.1);
    z-index: 999;
}

/****************** custom animates css for step form ******************/
.fadein {
 
    -webkit-animation: fadeinout 2s linear backwards;
    animation: fadein 2s linear backwards;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0%{ opacity: 0; }
  100% { opacity: 1; }
}

.fadeout {

    -webkit-animation: fadeinout 2s linear forwards;
    animation: fadeinout 2s linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 0; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 0; }
}





@media(max-width:1024px)
{
    .cards-c-title {
             font-size: 2.2rem!important;
             word-spacing: unset!important;
             line-height:35px;
      }

}
@media(max-width:768px)
{
    .cards-c-title {
             font-size: 2.2rem!important;
             word-spacing: unset!important;
             line-height:35px;
      }
      .dash-cards
{
    .top-body {
        min-height: 240px;
    }
}
}